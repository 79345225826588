<!-- Template -->
<template>
  <ClientOnly>
    <Teleport to="#teleports">
      <div 
        class="a-request-modal"
        :class="[
          { 'a-request-modal--in': data.modal.isActive === true },
          { 'a-request-modal--out': data.modal.isActive === false }
        ]"
      >
        <div 
          class="a-request-modal__wrapper"
          :class="`a-request-modal__wrapper--type-${data.modal.type}`"
        >
          <!-- Botão de fechamento do modal -->
          <button 
            type="button"
            class="a-request-modal__wrapper__close"
            @click="() => handleEmitEvent('modal:close')"
          ><IconCancelCircle :width="25" :height="25" /></button>
    
          <!-- Conteúdo do modal -->
          <div class="a-request-modal__wrapper__content">
            <!-- Titulo do conteudo do modal -->
            <h1
              v-if="data.modal.title"
              class="a-request-modal__wrapper__content__title"
              :class="`a-request-modal__wrapper__content__title--align-${
                data.modal.title.align ? data.modal.title.align : 'center'
              }`"
            >{{ data.modal.title.text }}</h1>

            <!-- Conteudo do modal -->
            <div
              v-if="data.modal.content"
              v-html="data.modal.content.text"
              class="a-request-modal__wrapper__content__message"
              :class="`a-request-modal__wrapper__content__message--align-${
                data.modal.content.align ? data.modal.content.align : 'left'
              }`"
            />

            <!-- Botões de ação do modal -->
            <div class="a-request-modal__wrapper__content__actions">
              <button
                v-if="data.modal.actions && data.modal.actions.length"
                v-for="action in data.modal.actions"
                :key="`${action.label}`"
                type="button"
                class="a-request-modal__wrapper__content__actions__button"
                :class="{ 'a-request-modal__wrapper__content__actions__button--outline': action.outline }"
                @click="() => handleEmitEvent(action.emit)"
              >{{ action.label }}</button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<!-- Script -->
<script setup>
// Icones
import IconCancelCircle from '~/assets/icons/IconCancelCircle.vue'

// Eventos
const emit = defineEmits(['modal:close'])

// Propriedades
const props = defineProps(['injectKey'])

// Injeção de dados
const { data } = inject(props.injectKey)

// Métodos
function handleEmitEvent(event) {
  emit(event)
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-request-modal {
  @include modal-default();

  &__wrapper &__wrapper__content {
    display: grid;
    place-items: start stretch;
    row-gap: 32px;

    &__title {
      &--align-left {
        @include font-custom(20, 24, left, "Montserrat Bold");
      }

      &--align-center {
        @include font-custom(20, 24, center, "Montserrat Bold");
      }

      &--align-right {
        @include font-custom(20, 24, right, "Montserrat Bold");
      }

      &--align-justify {
        @include font-custom(20, 24, justify, "Montserrat Bold");
      }
    }

    &__message {
      display: grid;
      place-items: start stretch;

      &--align-left {
        @include font-custom(16, 18, left);
      }

      &--align-center {
        @include font-custom(16, 18, center);
      }

      &--align-right {
        @include font-custom(16, 18, right);
      }

      &--align-justify {
        @include font-custom(16, 18, justify);
      }

      :deep(p) {
        a {
          text-decoration: underline;
          color: $alfa-brand-primary-1;
        }

        a + small {
          margin-left: 8px;
        }
      } 
    }

    &__actions {
      display: grid;
      place-content: start stretch;
      place-items: center;
      row-gap: 24px;
      margin-top: 8px;

      @media screen and (min-width: $min-tablet) {
        column-gap: 32px;
        grid-auto-flow: column;
      }

      &__button {
        &:not(&--outline) {
          @include button-preset('small', 1);
        }

        &--outline {
          @include button-preset('small', 2);
        }
      }
    }
  }
}

</style>